import { Route, RouteGroup } from '@/mixins/easy-route'
import { concat } from 'lodash'

export default concat(
  RouteGroup({

    middleware: ['auth', 'password-set', 'active-subscription'],

    routes: [
      Route('/sites', 'site-admin-index', () => import('@/views/site/AdminIndex')),
      Route('/site', 'site-admin-create', () => import('@/views/site/Create'), ['create-sites']),
    ],

  }),
  RouteGroup({
    middleware: ['auth', 'password-set', 'active-subscription'],

    parent: Route('/site/:site', '', () => import('@/views/site/manager/Index')),

    routes: [
      Route('/', 'site-overview', () => import('@/views/site/manager/Overview')),
      Route('zones', 'site-zones', () => import('@/views/site/manager/Zones'), ['manage-zones']),
      Route('assets', 'site-assets', () => import('@/views/site/manager/Assets'), ['manage-assets']),
      Route('jobs', 'site-jobs', () => import('@/views/site/manager/Jobs')),
      Route('jobs/deleted', 'site-deleted-jobs', () => import('@/views/site/manager/DeletedJobs'), ['view-deleted-jobs']),
      Route('assets/decommissioned', 'site-decommissioned-assets', () => import('@/views/site/manager/DecommissionedAssets'), ['view-deleted-jobs']),
      Route('inspections', 'site-inspections', () => import('@/views/site/manager/Inspections')),
      Route('edit', 'site-edit', () => import('@/views/site/manager/Edit'), ['edit-site']),
      Route('user-access', 'site-user-access', () => import('@/views/site/manager/UserAccess'), ['manage-user-access']),
      Route('documents', 'site-documents', () => import('@/views/site/manager/Documents'), ['site-manage-documentation']),
      Route('tools', 'site-tools', () => import('@/views/site/manager/Tools')),
      Route('custom-fields', 'site-custom-fields', () => import('@/views/site/manager/custom-fields/CustomFieldsIndex'), ['update-custom-fields']),
      Route('custom-field-create', 'site-create-custom-field', () => import('@/views/site/manager/custom-fields/CustomFieldCreate'), ['create-custom-fields']),
      Route('custom-field/:customField', 'site-custom-field-edit', () => import('@/views/site/manager/custom-fields/CustomFieldEdit'), ['update-custom-fields']),
      Route('custom-field/:customField/predefined-list-create', 'site-create-predefined-list', () => import('@/views/site/manager/custom-fields/PredefinedAnswersIndex'), ['create-custom-fields']),
      Route('asset-register', 'site-asset-register', () => import('@/views/site/manager/asset-register/Index')),
    ],
  }),
)
